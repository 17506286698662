import clsx from "clsx";
import { PostMedia } from "..";
import { TopicPopup, useTopicsModalQuery } from "../../features";
import s from "./summary.module.css";


type Props = {
  data: any;
  number: number;
  className?: string;
  referenceId?: string;
  tonal?: string;
  tonalFilter?: any
};

export const Summ = ({ number, className, data, referenceId, tonal, tonalFilter}: Props) => {

  const publicationsQuery = useTopicsModalQuery({
    id: data.id.length > 10 ? data.id : data.group_id,
    type: 'smi',
    referenceFilter: [parseInt(referenceId!)]
  });

  const postMediaData = {
    id: data.id,
    group_id: data.group_id,
    topic: data.title,
    subject: data.reference_item,
    level: 0,
    subjectPublications: data.postcount,
    subjectCoverage: data.attendance,
    totalPublications: data.total_posts,
    totalCoverage: data.total_attendance,
    dynamicData: data.total_graph,
    mediaPublications: data.smi_total_posts,
    socialMediaPublications: data.social_total_posts,
    storyDocs: data.storyDocs,
    lastHourDocs: data.lastHourDocs,
    publications: publicationsQuery
  };


  const postNetural = publicationsQuery.data?.items.filter((it: any) => it.trust.trust === 0).length;;
  const postNegative = publicationsQuery.data?.items.filter((it: any) => it.trust.trust === -1).length;;
  const postPositive = publicationsQuery.data?.items.filter((it: any) => it.trust.trust === 1).length;;
  const postSumm = postNetural + postNegative + postPositive;
  tonal = postNegative*100/postSumm >= 41 ? 'trending_negative' : postPositive*100/postSumm >= 41 ? 'trending_positive' : 'neutral'
  const postFederal = publicationsQuery.data?.items.filter((it: any) => it.smi_type === "federal") || [];

  //tonalFilter(tonal)

  const content = (
    <div className={className}>
      {tonalFilter === "" &&
        <PostMedia
          number={0}
          coat={postFederal.length > 0}
          className={clsx(s.topic, s[tonal])}
          data={postMediaData}
        />
      }
      {tonalFilter === tonal &&
        <>
          <PostMedia
            number={0}
            coat={postFederal.length > 0}
            className={clsx(s.topic, s[tonal])}
            data={postMediaData}
          />
        </>
      }
    </div>
  );


  return <TopicPopup content={content} data={postMediaData} referenceId={referenceId} profileId='all'/>;
};
