// import clsx from "clsx";
import clsx from "clsx";
import {ChangeEvent, useEffect, useState} from "react";
import {
  useThreadsQuery,
  useTopicsQuery,
  useRefSourcesQuery,
  useSmiQuery,
  mergeQueries,
  useMainTopicQuery,
  useTopicsModalQuery,
} from "../../features";
import { useAppSelector } from "../../redux";
import {
  Button,
  PageTitle,
  Panel,
  ApiInfo,
  NoData,
  Topic,
  Field,
  Select,
  Summ,
} from "../../ui";
import s from "./summary.module.css";
import CustomSelect from "../../ui/custom-select/custom-select";

export const Summary = () => {
  type SateType = "subject" | "event" | "smi";
  type PeriodType = "day" | "week" | "month";
  type TonalType = "" | "neutral" | "trending_positive" | "trending_negative";
  const [type, setType] = useState<SateType>("subject");
  const [period, setPeriod] = useState<PeriodType>("day");
  const [tonal, setTonal] = useState<TonalType>("");
  const [newTonal, setNewTonal] = useState<string[]>([]);
  const [themes, setThemes] = useState<number>(607);
  const [precached, setPrecached] = useState<boolean>(true);
  const [currentSourceId, setCurrentSourceId] = useState<string>("");
  const [userFilter, setUserFilter] = useState<{network_id: number, profile_id: string} | null>(null)

  const { REACT_APP_EVENTS_ID, REACT_APP_SUBJECTS_ID, REACT_APP_THREAD_ID } = process.env;

  const thread_id = +REACT_APP_THREAD_ID!;
  const reference_id =
    type === "event" ? REACT_APP_EVENTS_ID : REACT_APP_SUBJECTS_ID;

  const topic = useAppSelector((state) => state.topics.queries);

  const sourcesQuery = useRefSourcesQuery({
    thread_id: thread_id,
    // network_id: ['4'],
    // search_string: '',
    referenceFilter: reference_id ? [+reference_id] : undefined,
    start: 0,
    limit: 100
  });
  const sourcesList = sourcesQuery.data?.items ?? [];

  // I have no idea why, but we have to pass all of this
  // to the api (even if it works without)
  const wtfHardCodedParams = {
    referenceFilter: themes,
    type: "smi",
    from: "",
    to: "",
  };

  // ! ADD INFINITE SCROLLING
  const topicsParams = {
    start: 0,
    limit: 2000,
    thread_id,
    period,
    ...wtfHardCodedParams,
  };

  const skipParams = { skip: !thread_id || type === "smi" };
  const topicsQuery = useMainTopicQuery(topicsParams, skipParams);

  const smiQuery = useSmiQuery("", { skip: type !== "smi" });
  const allQueries = mergeQueries(topicsQuery, smiQuery);

  console.info(`🔥 allQueries`, allQueries);
  const { isSuccess, isFetching, isError, error } = allQueries;
  const items = type === "smi" ? smiQuery.data : topicsQuery.data?.items;
  const [filteredData, setFilteredData] = useState([] as any[]);
  const [searchString, setSearchString] = useState('');
  const [onlyFederal, setOnlyFederal] = useState(false);

  useEffect(() => {
    if (currentSourceId) {
      const currentSource = sourcesList.data?.find((it: any) => it.inner_id === currentSourceId)
      if (currentSource) {
        setUserFilter({
          network_id: currentSource.network_id,
          profile_id: currentSource.inner_id
        })
      }
    } else {
      setUserFilter(null)
    }
  }, [currentSourceId])

  useEffect(() => {
    setFilteredData([])
    if (items) {
      const sortedItems = [...items].sort((a:any, b:any) => a.attendance < b.attendance ? 1 : -1)
      setFilteredData(onlyFederal ? sortedItems.filter((it: any) => it.smi_type === "federal") : sortedItems)
    }
  }, [onlyFederal, items])

  const newPeriod = (evt:PeriodType) => {
    setPeriod(evt)
    if (evt !=="day")
      setPrecached(false)
    else
      setPrecached(true)
  }

  const themeTonal = (value:string) => {
    console.log(topic)
    console.log(value)
    newTonal.push(value)
  }

  return (
    <>
      <PageTitle>Сводка тем</PageTitle>
      <Panel className={s.filter} padding>
        <div className={s.filterLine}>
          {/*<Field
            placeholder="Поиск"
            icon="search"
            className={s.search}
            onChange={searchHandle}
            value={searchString}
          />*/}
          <Button
            className={s.tab}
            active={themes === 607}
            onClick={() => setThemes(607)}
          >
            Беглов А.Д., губернатор Санкт-Петербурга
          </Button>
          <Button
            className={s.tab}
            active={themes === 1331}
            onClick={() => setThemes(1331)}
          >
            Бельский А.Н., председатель Законодательного собрания
          </Button>
          <Button
            className={s.tab}
            active={themes === 1334}
            onClick={() => setThemes(1334)}
          >
            Депутаты Законодательного собрания
          </Button>
          <Button
            className={s.tab}
            active={themes === 1535}
            onClick={() => setThemes(1535)}
          >
            Вице-губернаторы Санкт-Петербурга
          </Button>
          <Button
            className={s.tab}
            active={themes === 1289}
            onClick={() => setThemes(1289)}
          >
            Администрации районов Санкт-Петербурга
          </Button>

          <Button
            className={s.tab}
            active={themes === 1391}
            onClick={() => setThemes(1391)}
          >
            Партия (Единая Россия)
          </Button>
          <Button
            className={s.tab}
            active={themes === 1580}
            onClick={() => setThemes(1580)}
          >
            Другие партии
          </Button>
          <Button
            className={s.tab}
            active={themes === 1589}
            onClick={() => setThemes(1589)}
          >
            Муниципальные депутаты
          </Button>

        </div>
        <div className={s.filterLine}>
          <label className={s.range}>
            <span className={s.label}>Период</span>
            <CustomSelect
              items={[
                { id: 'day', keyword: 'День' },
                { id: 'week', keyword: 'Неделя' },
                { id: 'month', keyword: 'Месяц' }
              ]}
              onChange={(value) => newPeriod(value as PeriodType)}
              value={period}
              type="interval"
            />
            <span className={s.label}>Тональность</span>
            <CustomSelect
              items={[
                { id: '', keyword: 'Любая' },
                { id: 'neutral', keyword: 'Нейтральные' },
                { id: 'trending_positive', keyword: 'Позитивные' },
                { id: 'trending_negative', keyword: 'Негативные' }
              ]}
              onChange={(value) => setTonal(value as TonalType)}
              value={tonal}
              type="interval"
            />
          </label>
        </div>
      </Panel>

      <ApiInfo isLoading={isFetching} isError={isError} error={error} />
      {isSuccess && filteredData.length < 1 && <NoData />}
      {isSuccess && filteredData.length > 0 && (
        <div className={s.list}>
          {filteredData?.map((item: any, index: number) => (
            <Summ 
              key={item.id + '_' + index} 
              number={index + 1} data={item} 
              referenceId={reference_id} 
              tonalFilter={tonal}/>
          ))}
        </div>
      )}
    </>
  );
};
