import {useEffect, useState} from "react";
import {useGetAllPostsQuery} from "../../features";
import {ApiInfo, Field, PageTitle, Panel} from "../../ui";
import {PressSecretary} from "./press-secretary";
import s from "./press-secretaries.module.css";
import {ApiServices} from "../../services/api-services";
import moment from "moment";
import CustomSelect from "../../ui/custom-select/custom-select";

export function PressSecretaries() {
  const [allDataLoaded, setAllDataLoaded] = useState(false)
  const [items, setItems] = useState<any[]>([])
  const [searchIOGV, setSearchIOGV] = useState('');
  const [iogvList, setIogvList] = useState<Array<{id: string, keyword: string}>>([]);
  const [iogvListIsLoading, setIogvListIsLoading] = useState(true);
  const [filteredData, setFilteredData] = useState<Array<any>>([]);

  const queryData = useGetAllPostsQuery({})
  const { data, isLoading, isError, error } = queryData;

  useEffect(() => {
    if (data) {
      getAllData()
    }
  }, [data])

  const getAllData = async () => {
    setIogvListIsLoading(true)
    const tempResult: any[] = []
    for (const materialItem of data) {
      const extendedData = await ApiServices.getMaterialItem({thread_id: +materialItem.id!})
      if (extendedData.data.thread_id !== 0 && extendedData.data.author_iogv) {
        tempResult.push({
          ...materialItem,
          author_iogv: extendedData.data.author_iogv
        })
      }
    }
    const authorIogvList = new Set(tempResult.map((it: any) => it.author_iogv))
    setIogvList([{ id: '', keyword: 'Все' }, ...Array.from(authorIogvList).map((it: string) => ({ id: it, keyword: it }))])
    setIogvListIsLoading(false)
    const result: { authorIogv: any; ids: number[]; riskActive: number; riskSpent: number, graphData: {name: string, value: number}[] }[] = []
    const riskListResponse = await ApiServices.getRiskList({ start: 0, limit: 1000, filter: {author: '', iogv_id: 0, status: ''} })
    const riskList: any[] = []
    if (riskListResponse.data.items) {
      for (const risk of riskListResponse.data.items) {
        const riskItemResponse = await ApiServices.getRiskItem({ id: risk.id })
        if (riskItemResponse.data.responsible_id.title) {
          riskList.push({
            authorIogv: riskItemResponse.data.iogv_id.title,
            status: riskItemResponse.data.status
          })
        }
      }
    }

    authorIogvList.forEach((it: any) => {
      const graphData: {name: string, value: number}[] = []
      const createdDateList = Array.from(new Set(tempResult.filter((item: any) => item.author_iogv === it).map((it: any) => it.created_date.split(' ')[0]))).sort((a:any, b:any) => a > b ? 1 : -1)
      createdDateList.forEach((createdDate: string) => {
        graphData.push({
          name: createdDate,
          value: tempResult.filter((item: any) => item.author_iogv === it && item.created_date.split(' ')[0] === createdDate).length
        })
      })

      result.push({
        authorIogv: it,
        ids: tempResult.filter((item: any) => item.author_iogv === it).map((it: any) => Number(it.id)),
        graphData,
        riskActive: riskList.filter((item: any) => item.authorIogv === it && item.status === 'Выполняется').length,
        riskSpent: riskList.filter((item: any) => item.authorIogv === it && item.status === 'Отработан').length
      })
    })

    setItems(result.sort((a:any, b:any) => a.ids.length < b.ids.length ? 1 : -1))
    setAllDataLoaded(true)
  }

  useEffect(() => {
    if (items) {
      let tempItems = [...items]
      if (searchIOGV !== '') {
        tempItems = [...tempItems].filter((it: any) => it.authorIogv?.toLowerCase().includes(searchIOGV.toLowerCase()))
      }
      setFilteredData(tempItems)
    }
  }, [searchIOGV, items])

  const searchHandle = () => {

  }

  return (
    <>
      <PageTitle>Работа Пресс-секретарей</PageTitle>
      <Panel className={s.search} padding>
        <Field
          placeholder="Найти по названию"
          icon="search"
          className={s.search}
          iconClickHandle={searchHandle}
        />
        <div className={s.field}>
          <span>ИОГВ</span>
          <CustomSelect
            items={iogvList}
            isLoading={iogvListIsLoading}
            onChange={(value) => setSearchIOGV(String(value))}
            value={searchIOGV}
            withSearch={true}
            isWide={true}
          />
        </div>
      </Panel>

      <ApiInfo isLoading={isLoading || !allDataLoaded} isError={isError} error={error} />

      {allDataLoaded && (
        <div className={s.list}>
          {filteredData.map((item: any, index: number) => (
            <PressSecretary key={item.authorIogv} number={index + 1} {...item} />
          ))}
        </div>
      )}
    </>
  );
}
