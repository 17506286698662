import {useSearchParams} from "react-router-dom";
import {PressSecretaryInfoItem} from "./press-secretary-info-item";
import {PageTitle} from "../../ui";

export const PressSecretaryInfo = () => {
  const [searchParams] = useSearchParams();
  const ids = searchParams.get('threads') || '[]'

  return (
    <>
      <PageTitle>Готовые материалы</PageTitle>
      <div>
        {JSON.parse(ids).map((id: number) => <PressSecretaryInfoItem key={id} id={id} />)}
      </div>
    </>
  )
}
