import clsx from "clsx";
import { useEffect } from "react";
import { PostMedia } from "..";
import { TopicPopup, useTopicsModalQuery } from "../../features";
import s from "./topic.module.css";

type Props = {
  data: any;
  number: number;
  className?: string;
  referenceId?: string;
  tonal?: string;
  profileId?: string;
  networkId?: string;
  updateData?: any;
  updateFederal?: any;
  type?: string
};

export const Topic = ({
  number,
  className,
  data,
  referenceId,
  tonal,
  profileId,
  networkId,
  updateData,
  updateFederal,
  type
}: Props) => {
  const sourseType = data?.url == "" ? "social" : "smi";

  const publicationsQuery = useTopicsModalQuery({
    id: data?.id?.length > 10 ? data?.id : data?.group_id,
    type: sourseType,
    referenceFilter: [parseInt(referenceId!)],
  });

  const postMediaData = {
    id: data?.id,
    group_id: data?.group_id,
    topic: data?.title,
    subject: data?.reference_item,
    level: 0,
    subjectPublications: data?.postcount,
    subjectCoverage: data?.attendance,
    totalPublications: data?.total_posts,
    totalCoverage: data?.total_attendance,
    dynamicData: data?.total_graph,
    mediaPublications: data?.smi_total_posts,
    socialMediaPublications: data?.social_total_posts,
    storyDocs: data?.storyDocs,
    lastHourDocs: data?.lastHourDocs,
    publications: publicationsQuery,
  };

  const postNetural = publicationsQuery.data?.items.filter(
    (it: any) => it.trust.trust === 0
  ).length;
  const postNegative = publicationsQuery.data?.items.filter(
    (it: any) => it.trust.trust === -1
  ).length;
  const postPositive = publicationsQuery.data?.items.filter(
    (it: any) => it.trust.trust === 1
  ).length;
  const postSumm = postNetural + postNegative + postPositive;
  tonal =
    (postNegative * 100) / postSumm >= 41
      ? "trending_negative"
      : (postPositive * 100) / postSumm >= 41
      ? "trending_positive"
      : "";

      const postFederal = publicationsQuery.data?.items.filter(
        (it: any) => it.smi_type === 'federal'
      ).length;


  useEffect(() => {
    const listOfSources = publicationsQuery.data?.items.map(
      (it: { owner_id: any; author: any; }) => ({
        id: [
          it.owner_id,
        ].toString(),
        keyword: it.author,
      })
    );
    console.log("listOfSources", listOfSources);
    updateData(listOfSources)

    const postsFederal = {
        id:data?.id ,
        federal: postFederal,

      }
    updateFederal(postsFederal)
  }, [publicationsQuery.data?.items]);

  const postBySource = publicationsQuery.data?.items.filter(
    (it: any) => it.owner_id === profileId
  ).length;

  const content = (
    <div className={className}>
      {profileId != "all" && profileId != "" && postBySource > 0 && (
        <PostMedia
          number={number}
          coat={data?.smi_type === "federal" || postFederal > 0}
          className={clsx(s.topic, s[tonal])}
          data={postMediaData}
        />
      )}
      {(profileId == "" || profileId == "all") &&(
        <PostMedia
          number={number}
          coat={data?.smi_type === "federal" || postFederal > 0}
          className={clsx(s.topic, s[tonal])}
          data={postMediaData}
        />
      )}
    </div>
  );

  return (
    <TopicPopup
      content={content}
      data={postMediaData}
      referenceId={referenceId}
      profileId={profileId}
      type={type}
    />
  );
};
