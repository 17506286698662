import clsx from "clsx";
import {
  BarChart,
  Man,
  Panel,
  PopupContent,
  Post,
  PostMediaData,
  TopicDescription,
} from "../../../ui";
import { Popup } from "../../../ui";
import { Publication } from "../../publications";
import s from "./topic-popup.module.css";
import { useThreadsQuery } from "../../threads";
import { useTopicsModalQuery } from "../topics-api";
import { trustMap } from "../../../data";
import {Bar, Tooltip, XAxis, YAxis} from "recharts";
import {useEffect, useState} from "react";

type Props = {
  content: PopupContent;
  data: PostMediaData;
  className?: string;
  referenceId?: string;
  isTrending?: boolean;
  profileId?: string;
  type?: string
};

export const TopicPopup = ({
  content,
  className,
  data,
  referenceId,
  isTrending = false,
  profileId,
  type
}: Props) => {
  const [graphData, setGraphData] = useState<Array<{name: string, value: number}>>([])
  const publicationsQuery = data.publications;

  let postBySource = publicationsQuery.data?.items;

  if (profileId != "" && profileId != "all")
    postBySource = publicationsQuery.data?.items.filter(
      (it: any) => it.owner_id === profileId
    );

  useEffect(() => {
    if (publicationsQuery?.data?.items) {
      const graphData: {name: string, value: number}[] = []
      const createdDateList: string[] = Array.from(new Set(publicationsQuery.data.items.map((it: any) => it.created_date.split(' ')[0]))).sort((a:any, b:any) => a > b ? 1 : -1) as string []
      createdDateList.forEach((createdDate: string) => {
        graphData.push({
          name: createdDate,
          value: publicationsQuery.data.items.filter((item: any) => item.created_date.split(' ')[0] === createdDate).length
        })
      })
      setGraphData(graphData)
    }
  }, [publicationsQuery])

  const init = 0;
  const likes = publicationsQuery.data?.items
    ?.map((it: any) => it)
    ?.map((it: { likes: any }) => it.likes);
  const sumLikes = likes?.reduce(
    (it: string | number, next: string | number) => +it + +next,
    init
  );

  const reposts = publicationsQuery.data?.items
    ?.map((it: any) => it)
    ?.map((it: { reposts: any }) => it.reposts);
  const sumReposts = reposts?.reduce(
    (it: string | number, next: string | number) => +it + +next,
    init
  );

  const comments = publicationsQuery.data?.items
    ?.map((it: any) => it)
    ?.map((it: { comments: any }) => it.comments);
  const sumComments = comments?.reduce(
    (it: string | number, next: string | number) => +it + +next,
    init
  );

  const viewed = publicationsQuery.data?.items
    ?.map((it: any) => it)
    ?.map((it: { viewed: any }) => it.viewed);
  const sumViewed = viewed?.reduce(
    (it: string | number, next: string | number) => +it + +next,
    init
  );

  let lenght = publicationsQuery?.data?.items?.length

  const bot = lenght > 10 ? Math.round((lenght-5)*50/lenght) : 0;

  return (
    <Popup className={clsx(className, s.root)} content={content}>
      <div className={s.header}>
        <div className={s.headerWrap}>
          <h4>Тема: {publicationsQuery.data?.title}</h4>
          <div className={clsx(s.alldata)}>
            <div className={s.descriptions}>
            {data.subjectPublications && !isTrending ? (
              <TopicDescription
                title="Публикаций с упоминанием субъекта"
                description={data.subjectPublications}
                coverage={data.subjectCoverage}
              />
            ) : null}
            {data.totalPublications && (
              <TopicDescription
                title={
                  isTrending
                    ? "Количество уникальных источников"
                    : "Всего публикаций в теме"
                }
                description={data.totalPublications}
                coverage={data.totalCoverage}
              />
            )}
            {publicationsQuery.data?.items.filter(
              (it: any) => it.network_id === "4"
            ).length != 0 && (
              <TopicDescription
                title="Количество публикаций в СМИ"
                description={
                  publicationsQuery.data?.items.filter(
                    (it: any) => it.network_id === "4"
                  ).length || 0
                }
              />
            )}
            {publicationsQuery.data?.items.filter(
              (it: any) => it.network_id !== "4"
            ).length != 0 && (
              <TopicDescription
                title="Количество публикаций в cоц.сетях"
                description={
                  publicationsQuery.data?.items.filter(
                    (it: any) => it.network_id !== "4"
                  ).length || 0
                }
              />
            )}
            <div className={s.tonal}>
              <TopicDescription
                title="Нейтральных"
                description={
                  publicationsQuery.data?.items.filter(
                    (it: any) => it.trust.trust !== 1 && it.trust.trust !== -1
                  ).length || 0
                }
              />
              <TopicDescription
                title="Позитивных"
                description={
                  publicationsQuery.data?.items.filter(
                    (it: any) => it.trust.trust === 1
                  ).length || 0
                }
              />
              <TopicDescription
                title="Негативных"
                description={
                  publicationsQuery.data?.items.filter(
                    (it: any) => it.trust.trust === -1
                  ).length || 0
                }
              />
            </div>
            {sumLikes > 0 ||
            sumComments > 0 ||
            sumReposts > 0 ||
            sumViewed > 0 ? (
              <>
                <div className={s.tonal}>
                  <TopicDescription title="Лайков" description={sumLikes} />
                  <TopicDescription
                    title="Комментариев"
                    description={sumComments}
                  />
                  <TopicDescription title="Репостов" description={sumReposts} />
                  <TopicDescription
                    title="Просмотров"
                    description={sumViewed}
                  />
                </div>
                <TopicDescription
                  title="Основной регион размещение"
                  description="Санкт-Петербург"
                />
              </>
            ) : (
              <TopicDescription
              title="Основной регион размещение"
              description="Санкт-Петербург"
            />
            )}
            </div>
            <div className={s.additionalInfo}>
              <div className={s.additionalInfoMan}>
                {sumLikes > 0 ||
                  sumComments > 0 ||
                  sumReposts > 0 ||
                  sumViewed > 0 ? (
                    <div className={s.man}>
                      <Man level={+bot} />
                      <p className={s.subject__caption}>Боты {bot}%</p>
                    </div>
                ) : (
                  ""
                )}
              </div>
              <div className={s.additionalInfoChart}>
                <p>Динамика размещения публикаций</p>
                <Panel padding className={s.panel}>
                  <BarChart className={s.barChart} data={graphData.map((it: any) => ({Публикаций: it.value, name: `${it.name.split('-')[2]}-${it.name.split('-')[1]}-${it.name.split('-')[0]}`}))} height={160}>
                    <Bar dataKey="Публикаций" fill="#4EC0E4" />
                    <Tooltip />
                    <XAxis angle={-70} dataKey="name" textAnchor="end" interval={0} height={86}/>
                    <YAxis />
                  </BarChart>
                </Panel>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={s.publications}>
        {publicationsQuery?.data && publicationsQuery?.data?.items
          ? postBySource?.map((it: any, id: number) => (
              // <Post
              //   title={it.title}
              //   author={it.author}
              //   text={it.text}
              //   coat={false}
              //   date={new Date(it.created_date)}
              //   statistics={{
              //     people: 0,
              //     views: 0,
              //     likes: 0,
              //     comments: 0,
              //     reposts: 0,
              //   }}
              // />
              <Publication
                key={it.id + id}
                post={{
                  date: new Date(it.created_date),
                  coat: it.smi_type !== "local",
                  id: it.id,
                  uri: it.uri,
                  title: it.title,
                  author: {
                    name: it.author,
                    avatar: it.author_logo,
                    url: it.author_url,
                  },
                  text: it.text,
                  statistics: {
                    people: it.attendance,
                    views: it.viewed,
                    likes: it.likes,
                    comments: it.comments,
                    reposts: it.reposts,
                  },
                  // @ts-ignore
                  type: trustMap[it.trust.trust],
                  media: {
                    images: it.images,
                    video: it.video,
                  },
                  networkName: it.network_name,
                }}
                actions={{
                  onCreateClick: () => console.log("onCreateClick"),
                  onExcludeClick: () => console.log("onExcludeClick"),
                  onReadClick: () => console.log("onReadClick"),
                  onToneClick: () => console.log("onToneClick"),
                }}
              />
            ))
          : null}
      </div>
    </Popup>
  );
};
